import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

const AboutPage = () => {
    return (
        <Layout>
            <Head title="About"/>
            <h1>About this blog - Frontiers of Finance in China</h1>
            <h2>Frontiers of Finance in China was started in 2015 to share research insights on emerging developments in money and finance in China.</h2>
            <p>The blog was part of research project on financial innovation and central banking in China, financial innovation started by Luke Deer, Mike Beggs, Chris Jefferis and Yu Yuxin, with additional research by Christopher Aston.</p>
            <p>The first phase of our research project was been funded by a grant from <a href="https://www.ineteconomics.org/research/grants/financial-innovation-and-central-banking-in-china-a-money-view" target="_blank" rel="noopener noreferrer">The Institute for New Economic Thinking (INET)</a></p>
            <p><Link to="/inet-grant-project-team">See here for more about our grant team.</Link></p>
        </Layout>
    )
}

export default AboutPage